import React from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import kkLogo from '../assets/images/kuaikan.png';

const SingleChapterStyles = styled.div`
  width: 100%;
  max-width: 720px;
  margin: 80px auto;
  @media (max-width: 480px) {
    margin-top: 40px;
  }
  .gatsby-image-wrapper {
    border: 0px solid var(--black);
    margin-bottom: 20px;
  }
  .endNav {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    a {
      display: block;
      padding: 0 16px;
      height: 48px;
      border-radius: 10px;
      text-decoration: none;
      text-align: center;
      line-height: 48px;
      background-color: var(--black);
      font-size: 18px;
      font-weight: bold;
      color: white;
      margin: 0 10px;
    }
    a:hover {
      transform: rotate(3deg) scale(1.1);
      background-color: var(--grey);
    }
  }
  .kkLink {
    width: 100%;
    max-width: 640px;
    margin: 80px auto 0;
    border: 3px solid var(--black);
    border-radius: 16px;
    padding: 50px 20px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .kkLogo {
      width: 64px;
      height: 64px;
      background: url(${kkLogo});
      background-size: cover;
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%) rotate(-10deg);
    }
    h3 {
      font-size: 18px;
      color: var(--black);
      margin: 20px 0 20px;
      text-align: center;
      line-height: 28px;
      a {
        text-decoration: none;
        font-weight: bold;
        color: #ffb800;
        margin: 0 4px;
      }
    }
    .supportBtn {
      width: 240px;
      height: 60px;
      border-radius: 10px;
      background-color: #ffd700;
      font-size: 24px;
      font-weight: bold;
      color: var(--black);
      line-height: 60px;
      text-align: center;
      text-decoration: none;
    }
    .supportBtn:hover {
      transform: rotate(-1.5deg) scale(1.1);
      background-color: #ffb800;
    }
  }
`;

export default function SingleChapterPage({ data }) {
  const { currentChapter } = data;
  const chapters = data.chapters.nodes.filter(
    (chapter) => chapter.book.slug.current === currentChapter.book.slug.current
  );
  console.log(chapters);
  let currentIndex = 0;
  chapters.forEach((chapter, i) => {
    if (chapter.slug.current === currentChapter.slug.current) {
      currentIndex = i + 1;
    }
  });

  let nextChapter = '';
  if (currentIndex < chapters.length) {
    nextChapter = chapters[currentIndex].slug.current;
    console.log(nextChapter);
  } else {
    console.log('已经是最新');
  }

  return (
    <SingleChapterStyles>
      {currentChapter.content.map((page, i) => (
        <Img fluid={page.asset.fluid} key={i} />
      ))}
      <div className="endNav">
        <Link to={`/${currentChapter.book.slug.current}`}>
          {currentChapter.book.name}
        </Link>
        <Link to={`/${currentChapter.book.slug.current}/${nextChapter}`}>
          {nextChapter === '' ? '已经到最新话了' : '下一话'}
        </Link>
      </div>
      <div className="kkLink">
        <div className="kkLogo" />
        <h3>
          《{currentChapter.book.name}》正在快看连载，如果觉得好看，请到
          <a href="https://www.kuaikanmanhua.com/web/topic/11164/">快看漫画</a>
          点关注，支持我继续创作，谢谢啦
        </h3>
        <a
          href="https://www.kuaikanmanhua.com/web/topic/11164/"
          className="supportBtn"
        >
          去支持一下
        </a>
      </div>
    </SingleChapterStyles>
  );
}

export const query = graphql`
  query ($slug: String!) {
    currentChapter: sanityChapter(slug: { current: { eq: $slug } }) {
      title
      slug {
        current
      }
      book {
        name
        slug {
          current
        }
      }
      content {
        asset {
          fluid(maxWidth: 1200) {
            ...GatsbySanityImageFluid
          }
        }
      }
      _updatedAt(formatString: "YYYY-MM-DD")
    }
    chapters: allSanityChapter(sort: { order: ASC, fields: publishedAt }) {
      nodes {
        slug {
          current
        }
        id
        book {
          name
          slug {
            current
          }
        }
      }
    }
  }
`;
